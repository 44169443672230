import { contact } from './modules/contact';
import { headerAction } from './modules/headerAction';
import { scrollmagic } from './modules/scrollmagic';
import { slider } from './modules/slider';
import { smooth } from './modules/smooth';
import { topModal } from './modules/topModal';
import { windowAction } from './modules/windowAction';
smooth();
slider();
headerAction();
scrollmagic();
windowAction();
contact();
topModal();

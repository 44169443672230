export function topModal() {
  if (document.querySelector('.js-modalMovieCont')) {
    const movieTriggerEle = document.querySelector('.js-movieTrigger');
    const modalMovieContEle = document.querySelector('.js-modalMovieCont');
    const modalClose = document.querySelector('.js-modalClose');

    movieTriggerEle.addEventListener('click', () => {
      if (modalMovieContEle.classList.contains('is-view')) {
        modalMovieContEle.classList.remove('is-view');
      } else {
        modalMovieContEle.classList.add('is-view');
      }
    });

    modalClose.addEventListener('click', () => {
      modalMovieContEle.classList.remove('is-view');
    });

    modalMovieContEle.addEventListener('click', () => {
      modalMovieContEle.classList.remove('is-view');
    });
  }
}

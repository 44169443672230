export function headerAction() {
  const hamburgerEle = document.querySelector('.js-hamburger');
  const headerNavEle = document.querySelector('.js-headerNav');
  const megaTriggerEle = document.querySelectorAll('.js-megaTrigger');
  const bodyEle = document.querySelector('body');

  hamburgerEle.addEventListener('click', () => {
    if (hamburgerEle.classList.contains('is-active')) {
      hamburgerEle.classList.remove('is-active');
      headerNavEle.classList.remove('is-view');
      bodyEle.classList.remove('is-fixed');
    } else {
      hamburgerEle.classList.add('is-active');
      headerNavEle.classList.add('is-view');
      bodyEle.classList.add('is-fixed');
    }
  });

  megaTriggerEle.forEach((target) => {
    target.addEventListener('mouseover', () => {
      const megaNavEle = target.querySelector('.js-megaNav');
      megaNavEle.classList.add('is-open');
    });

    target.addEventListener('mouseleave', () => {
      const megaNavEle = target.querySelector('.js-megaNav');
      megaNavEle.classList.remove('is-open');
    });
  });
}

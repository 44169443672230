import ScrollMagic from 'scrollmagic';

export function scrollmagic() {
  let controller = new ScrollMagic.Controller();
  let fadeIn = document.querySelectorAll('.js-scrollAnimation');

  for (let i = 0; i < fadeIn.length; i++) {
    let tag = fadeIn[i];

    new ScrollMagic.Scene({
      triggerElement: tag,
      triggerHook: 'onEnter',
      offset: 0,
    })
      .setClassToggle(tag, 'is-animated')
      .addTo(controller);
  }

  let controllerLeave = new ScrollMagic.Controller();
  let fadeInLeave = document.querySelectorAll('.js-scrollAnimationLeave');

  for (let i = 0; i < fadeInLeave.length; i++) {
    let tagLeave = fadeInLeave[i];

    new ScrollMagic.Scene({
      triggerElement: tagLeave,
      triggerHook: 'onLeave',
      offset: 0,
    })
      .setClassToggle(tagLeave, 'is-animated')
      .addTo(controllerLeave);
  }
}

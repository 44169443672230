import Swiper, { EffectFade, Autoplay, Pagination, Navigation } from 'swiper';

export function slider() {
  if (document.querySelector('.js-sliderIndex')) {
    const sliderIndex = new Swiper('.js-sliderIndex', {
      modules: [EffectFade, Autoplay, Pagination],
      effect: 'fade',
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      },
    });
  }

  if (document.querySelector('.js-sliderMember')) {
    const sliderIndex = new Swiper('.js-sliderMember', {
      modules: [Autoplay, Navigation],
      spaceBetween: 20,
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 7500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.js-sliderMemberNext',
        prevEl: '.js-sliderMemberPrev',
      },
      breakpoints: {
        768: {
          spaceBetween: 20,
          slidesPerView: 5,
        },
        1028: {
          spaceBetween: 44,
          slidesPerView: 5,
        },
      },
    });
  }

  if (document.querySelector('.js-topicsSlider')) {
    // init Swiper:
    const swiper = new Swiper('.js-topicsSlider', {
      // configure Swiper to use modules
      modules: [Navigation],
      slidesPerView: 1.5,
      spaceBetween: 20,
      // loop: true,
      // slidesPerGroup: 3,
      // centeredSlides: true,
      // grabCursor: true,
      // autoplay: {
      //   delay: 2500,
      //   disableOnInteraction: false,
      // },
      navigation: {
        nextEl: '.js-topicsSliderNext',
        prevEl: '.js-topicsSliderPrev',
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 3.5,
          spaceBetween: 30,
        },
      },
    });
  }
}

export function contact() {
  if (document.querySelector('.js-placeholder')) {
    const placeholderEle = document.querySelector('.js-placeholder');

    window.addEventListener('load', () => {
      if (placeholderEle.firstElementChild.value !== '') {
        placeholderEle.nextElementSibling.classList.add('is-hide');
      }
    });

    placeholderEle.firstElementChild.addEventListener('focus', () => {
      placeholderEle.nextElementSibling.classList.add('is-hide');
    });
    placeholderEle.firstElementChild.addEventListener('blur', () => {
      if (placeholderEle.firstElementChild.value === '') {
        placeholderEle.nextElementSibling.classList.remove('is-hide');
      }
    });
  }
}

import { throttle } from 'lodash';

export function windowAction() {
  const bnrEle = document.querySelector('.js-bnr');
  const bnrClose = document.querySelector('.js-bnrClose');

  window.addEventListener(
    'scroll',
    _.throttle(
      (e) => {
        headerScrolled();
        return false;
      },
      10,
      { trailing: true, leading: true }
    )
  );

  // ページ読み込み時にスクロールトップボタンがkvより下にあれば表示
  window.addEventListener('load', () => {
    headerScrolled();
  });

  function headerScrolled() {
    const headerEle = document.querySelector('.js-header');
    const scroll = window.pageYOffset;

    // スクロール量がkvの高さを超えたらページトップボタンを表示
    if (scroll > 100) {
      headerEle.classList.add('is-scrolled');
    } else {
      headerEle.classList.remove('is-scrolled');
    }
  }
}
